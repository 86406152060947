<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>申し込み<br class="sp">フォーム</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>申し込み内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>参加申し込みを<br class="sp">受け付けました</p>
          </div>
        </div>
        <div class="cts_box">
          <h2 class="sub_ttl"><span>申し込み内容の確認</span></h2>
          <p>
            以下の内容で研修会参加の申し込みを行います。
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">申し込み内容</h3>

          <template v-if="isUserSignedIn()">
            <div class="df_table">
              <dl class="flex">
                <dt>おなまえ</dt>
                <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>塾生番号</dt>
                <dd>{{ current_user.number }}</dd>
              </dl>
            </div>
          </template>

          <template v-else>
            <div class="df_table">
              <dl class="flex">
                <dt>氏名</dt>
                <dd>{{ user.last_name }} {{ user.first_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>性別</dt>
                <dd>{{ {secret: 'その他', male: '男性', female: '女性'}[user.gender] }}</dd>
              </dl>
              <dl class="flex">
                <dt>生年月日</dt>
                <dd>{{ user.birthday_year }}年 {{ user.birthday_month }}年 {{ user.birthday_day }}日</dd>
              </dl>
              <dl class="flex">
                <dt>メールアドレス</dt>
                <dd>{{ user.email }}</dd>
              </dl>
              <dl class="flex">
                <dt>電話番号</dt>
                <dd>{{ user.phone_number }}</dd>
              </dl>
              <dl class="flex">
                <dt>住所</dt>
                <dd>〒{{ user.zip }}<br>{{ user.prefecture }} {{ user.address1 }} {{ user.address2 }}<br>
                  {{ user.address3 }}
                </dd>
              </dl>
            </div>
          </template>
        </div>

        <div class="cts_box workshop_select">
          <h3 class="min_ttl">研修会メニュー</h3>
          <p class="lead_txt">現在以下のメニューが選択されています。</p>
          <select name="" id="" disabled="">
            <option value="join_type_offline"
                    v-if="model.join_type === 'join_type_both' || model.join_type === 'join_type_offline'">
              研修会参加費(現地)：¥{{
                delimited(model.rank_price)
              }}
            </option>
            <option value="join_type_online"
                    v-if="model.join_type === 'join_type_both' || model.join_type === 'join_type_online'">
              研修会参加費(オンライン)：¥{{
                delimited(model.rank_price_online)
              }}
            </option>
          </select>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">お会計内容</h3>
          <p class="lead_txt">参加を希望されるすべての研修会の合計額を表示しています。</p>
          <div class="register_box">
            <dl class="flex">
              <dt>{{ model.title }}</dt>
              <dd class="item" v-if="isUserSignedIn()">{{ current_user.rank_label }}</dd>
              <dd class="item" v-if="!isUserSignedIn()">一般</dd>
              <dd class="price">¥{{ delimited(user.price) }}</dd>
            </dl>

            <dl class="flex" v-if="user.use_coupon">
              <dt>クーポンのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">¥-{{ delimited(discountCouponPrice()) }}<span
                  v-if="false">受講額 ¥-{{ delimited(discountCouponPrice()) }}%オフ</span></dd>
            </dl>
            <dl class="flex" v-if="user.point">
              <dt>ポイントのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">-{{ delimited(user.point) }}</dd>
            </dl>
            <dl class="flex total">
              <dt></dt>
              <dd class="item">お会計合計：</dd>
              <dd class="price">¥{{ delimited(user.total_price) }}</dd>
            </dl>
          </div>
        </div>

        <div class="cts_box" v-if="!model.rank_regular_plus_request_enabled && model.lottery_enabled">
          <!-- 抽選-->
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">以下のボタンを押してください。（決済は抽選後に行われます）
            </p>
            <p class="alnC mb20">
              {{ model.lottery_lead_text }}
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">研修会参加の抽選に申し込む</button>
          </div>
        </div>

        <div class="cts_box" v-else-if="user.total_price > 0">
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">続いて代金の決済を行います
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">決済情報を入力する</button>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
          </div>
        </div>

        <div class="cts_box" v-else>
          <div class="button_wrap">
            <p class="alnC mb20">
              内容に変更なければ<br class="sp">以下のボタンを押してください。（決済は不要です）
            </p>
            <button type="button" class="submit light arrow" @click="paymentClicked()">研修会に申し込む</button>
          </div>
        </div>

        <div class="cts_box">
          <div class="button_wrap">
            <p class="alnC mb20">
              入力内容を変更する場合は<br class="sp">こちらのボタンを押してください
            </p>
            <router-link :to="{ name: 'WorkshopForm', params: {id: model.id}, query: $route.query}"
                         class="submit light skeleton arrow back">
              前のページにもどる
            </router-link>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      fetch_data: null,
      request_exec: false,
      model: null,
      errors: false,
      validation_errors: {},
      user: null,
      usable_points: 0,
      use_point: null,
      price: 0,
      total_price: 0
    }
  },
  mounted() {
    // form token
    if (!this.checkFormToken('Workshop')) return

    if (!this.$store.state.user) {
      this.$router.push({name: 'WorkshopForm', params: {id: this.$route.params.id}})
    } else {
      this.user = this.$store.state.user
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}workshop/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.workshop
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    discountCouponPrice() {
      if (!this.user.use_coupon) {
        return 0
      } else {
        return Math.min(this.user.price, this.fetch_data.coupon.price)
      }
    },

    paymentClicked() {
      if (this.request_exec) return
      this.request_exec = true

      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}workshop/paygent.json`, {
            workshop_user: this.user,
            draft: this.$route.query.draft
          })
          .then((response) => {
            // console.log(response)
            document.location.href = response.data.url
          })
          .catch((error) => {
            if (error.response.data.error) {
              alert(error.response.data.error)
            } else {
              this.errorAjax(error)
            }
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    },
  }
}
</script>
